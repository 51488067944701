import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "./layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { Toaster } from "react-hot-toast";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import SignIn from "views/auth/signIn";
import Register from "views/auth/register";
import ForgotPassword from "views/auth/forgot-password";
import { AuthProvider } from "contexts/authContext";
import { SWRConfig } from "swr";
import NotFoundPage from "views/admin/404/NotFoundPage";
import { IntercomProvider } from "react-use-intercom";
// import { useUserDetails } from "hooks/actions";
// import Intercom from "@intercom/messenger-js-sdk";

function localStorageProvider() {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem("app-cache", appCache);
  });

  // We still use the map for write & read for performance.
  return map;
}

const INTERCOM_APP_ID = "b0a2eoly";
ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <SWRConfig
        value={{
          refreshInterval: 30000,
          revalidateOnFocus: true,
          shouldRetryOnError: false,
          provider: localStorageProvider,
          keepPreviousData: true,
        }}
      >
        <ThemeEditorProvider>
          <BrowserRouter>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <AuthProvider>
                <Switch>
                  {/* <Route path={`/auth`} component={AuthLayout} /> */}
                  <Route path={`/admin`} component={AdminLayout} />
                  <Route path={`/auth/sign-in`} component={SignIn} />
                  <Route path={"/auth/register"} component={Register} />
                  <Route
                    path={`/auth/forgot-password`}
                    component={ForgotPassword}
                  />

                  <Redirect from="/" to="/auth/sign-in" />

                  <Route component={NotFoundPage} />
                </Switch>

                {/* <Switch>
                <Route path={`/admin`} component={AdminLayout} />
                <Route path={`/auth/sign-in`} component={SignIn} />
                <Route path={`/auth/register`} component={Register} />
                <Route
                  path={`/auth/forgot-password`}
                  component={ForgotPassword}
                />

                <Route component={NotFoundPage} />
              </Switch>
              {/* <Redirect from="/" to="/auth/sign-in" /> */}
              </AuthProvider>
            </IntercomProvider>
          </BrowserRouter>
        </ThemeEditorProvider>
      </SWRConfig>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
            },
          },
        }}
      />
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
