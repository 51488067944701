// chakra imports
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import { useAuth } from "contexts/authContext";
import React from "react";

// FUNCTIONS
function SidebarContent(props) {
  const { routes } = props;
  const { onClose } = props;

  const { logout } = useAuth();
  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links onClose={onClose} routes={routes} />
          {/* <Box>
            <Text>Help</Text>
          </Box> */}
          {/* <a href="#" borderRadius="8px" px="14px" onClick={logout}>
            <Text fontSize="md" color="red.400">
              Log out
            </Text>
          </a> */}
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
